import Head from 'next/head';
import Link from 'next/link';
import React from 'react';

import { RekaLink } from '@/components/reka-link';
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';

export function AuthLayoutNew({ pageTitle, children }: { pageTitle: string; children: React.ReactNode }) {
    return (
        <Flex flexDirection={'column'} minHeight={'var(--chakra-vh)'} width={'100vw'} background={'background-main'}>
            <Head>
                <title>{pageTitle}</title>
                <meta property="og:title" content={pageTitle} key="title" />
                <meta name="robots" content="noindex, nofollow" />
            </Head>
            <Box as={'header'} padding={'12px'}>
                <RekaLink href={'/auth/login'} />
            </Box>
            <Flex flex={1} justifyContent={'center'} alignItems={'center'}>
                {children}
            </Flex>
            <Box as={'footer'}>
                <Flex margin={'auto'} maxWidth="340px" gap={'18px'} fontSize={'12px'} paddingY={'24px'}>
                    <Link target={'_blank'} href={'https://reka.ai/terms-of-use/'}>
                        Terms
                    </Link>
                    <Link target={'_blank'} href={'https://reka.ai/privacy-policy/'}>
                        Privacy policy
                    </Link>
                </Flex>
            </Box>
        </Flex>
    );
}

export function LandingPage({ children }: { children: React.ReactNode }) {
    return (
        <Flex flexDirection={'column'} gap={'48px'} maxWidth={'340px'} width={'100%'}>
            <Example
                mediaUrl={'/images/apartment-example.jpeg'}
                prompt={'Help me write a property listing from this picture'}
            />
            <Box>
                <Heading color={'text-primary'} pb={'12px'} fontSize={'32px'} fontWeight={'600'} as={'h1'}>
                    Reka Playground
                </Heading>
                <Text color={'text-primary'} fontSize={'18px'} fontWeight={'500'}>
                    Explore the latest multimodal language models built by Reka
                </Text>
            </Box>

            <Box>{children}</Box>
        </Flex>
    );
}

function Example({ mediaUrl, prompt }: { mediaUrl: string; prompt: string }) {
    return (
        <Box>
            <Box
                width={'75%'}
                fontFamily={'monospace'}
                borderRadius={'6px'}
                border={'1px solid #8151BC'}
                backgroundColor={'background-main'}
                padding={'16px 20px'}
                position={'relative'}
                marginLeft={'auto'}
            >
                <Text>{prompt}</Text>
            </Box>
            <Image
                marginTop={'-30px'}
                borderRadius={'6px'}
                objectFit={'cover'}
                width={'100%'}
                src={mediaUrl}
                style={{
                    aspectRatio: '4/3',
                }}
                alt={'A photo of a staged living room in an apartment for sale'}
            />
        </Box>
    );
}
